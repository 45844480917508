@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&family=Noto+Sans+KR&family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.screen {
  background-color: rgb(27, 27, 27);
}

/* Menu: */
.light {
  background-color: white;
}

.dark {
  background-color: rgb(27, 27, 27);
}

.photo {
  width: 150px;
  padding-left: 20px;
  font-size: 20px;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
}

.photoDark {
  width: 150px;
  padding-left: 20px;
  font-size: 20px;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
  color: white;
}

.nav {
  font-family: 'Teko', sans-serif;  
  /* font-family: 'Noto Sans', sans-serif; */
  font-size: 40px;
  font-weight: 300;
}

.navDark {
  font-family: 'Teko', sans-serif;
  font-size: 30px;
  color: white;
}

.iconSize {
  height: 100px;
}


/* errormenu: */
.errorMenu {
  background: linear-gradient(270deg, cornflowerblue, #193D3C);
  -webkit-animation: waves 5s ease infinite;
  -moz-animation: waves 5s ease infinite;
  background-size: 200%;
  animation: waves 5s ease infinite;
}

.errorMenuImg {
  width: 18%;
  margin: auto;
  display: block;
}

@-webkit-keyframes waves {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes waves { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


/* Footer: */
.footer {
  background-color: rgb(27, 27, 27);
  color: white;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 300;  
}


/* Index Page: */
.title {
  font-size: 40px;
  color: white;
  font-family: 'Press Start 2P', cursive;
}

.titleSub {
  font-size: 40px;
  color: white;
  font-family: 'Teko', sans-serif;
  font-weight: 300;  
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 30px;
    color: white;
    font-family: 'Press Start 2P', cursive;
  }

  .titleSub {
    font-size: 30px;
    color: white;
    font-family: 'Teko', sans-serif;
    font-weight: 300;  
  }
}


/* Start Button: */
.buttonStart {
  background-color: rgb(27, 27, 27) ;
  border-style: solid;
  border-color: rgb(27, 27, 27);
  border-radius: 10px;
}

.blink {
  font-size: 20px;
  color: white;
  font-family: 'Press Start 2P', cursive;
  -webkit-animation: blink 1s steps(1, end) infinite;
          animation: blink 1s steps(1, end) infinite;
  padding: 5px;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Error 404 Button: */
.button404 {
  background-color: white ;
  border-style: solid;
  border-color: white;
  border-radius: 10px;
}

.text404 {
  font-size: 20px;
  color: black;
  font-family: 'Press Start 2P', cursive;
  padding: 5px;
}

.right {
  float: right;
  padding-right: 70px;
}


/* Section 01 (S1): */
.s1 {
  background-color: cornflowerblue;
  /* text-align: center; */
  /* font-family: 'Teko', sans-serif; */
  font-family: 'Noto Sans', sans-serif;
  font-weight: 300;
  font-size: 18px;
}

.s0 {
  background-color: cornflowerblue;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.s1Block {
  display: inline-block;
}

.s1TitleSub {
  font-size: 65px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;  
  text-align: center;
}

.s1TitleSubDark {
  font-size: 65px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  color: white;
}

@media only screen and (max-width: 600px) {
  .s1TitleSub {
    color: black;
    font-size: 40px;
    font-family: 'Teko', sans-serif;
    font-weight: 300;
  }
}

.s1Text {
  font-size:25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.s1video {
  width: 300px;
  height: auto;
  border-style: solid;
  border-color: black;
  /* border-right-width: 100px;
  border-left-width: 100px; */
  border-radius: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}


/* Section 02 (S2): */
.s2 {
  background-color: white;
}

.s2Dark {
  background-color: rgb(27, 27, 27);
}

.s2TitleSub {
  color: black;
  font-size: 65px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.s2TitleSubDark {
  color: white;
  font-size: 40px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.card {
  background-color: cornflowerblue;
  border-style: solid;
  border-width: 10px;
  border-color: cornflowerblue;
}
.project {
  background-color: cornflowerblue;
}

@media only screen and (max-width: 600px) {
  .s2TitleSub {
    color: black;
    font-size: 30px;
    font-family: 'Teko', sans-serif;
    font-weight: 300;
  }
}


/* Contact */
.contact {
  background-color: white;
}

.contactDark {
  background-color: rgb(27, 27, 27);
}


/* Portolio */
.portfolio {
  /* background-color: darkkhaki; */
  background-color: rgb(98, 107, 125);
}

.monk {
  visibility: hidden;
}

